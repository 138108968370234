const config = {
  url: `http://utbildning.svera.org`,
  name: `SVERA utbildning`,
  lang: `sv`,
  description: 'SVERA utbildningsportal på utbildning.svera.org',
  image: '/social_share.jpg',
}

module.exports = config

// import config from '../utils/config';
// <meta property="fb:app_id" content={config.url} />
